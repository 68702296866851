// extracted by mini-css-extract-plugin
export var cateChild = "department-module--cate-child--e9475";
export var cateItem = "department-module--cate-item--53a57";
export var catePanel = "department-module--cate-panel--d1da2";
export var fourColumn = "department-module--four-column--d7da9";
export var pDepartment = "department-module--p-department--bdf72";
export var showSearch = "department-module--showSearch--06ed9";
export var text = "department-module--text--2796c";
export var threeColumn = "department-module--three-column--bd0f5";
export var title3 = "department-module--title3--fb18e";
export var title4 = "department-module--title4--e8ba7";
export var top2 = "department-module--top2--984ec";